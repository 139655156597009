import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { ScannerInfo } from '@modules/orders-details/models/scanner-info';

export type OrdersDetailsState = EntityState<OrdersDetailsItem, number>;

export interface OrdersDetailsItem {
	orderId: number;
	rxId: string;
	scannerInfo: ScannerInfo;
}

@Injectable({
	providedIn: 'root'
})
@StoreConfig({ name: 'orders-details', resettable: true, idKey: 'orderId' })
export class OrdersDetailsStore extends EntityStore<OrdersDetailsState> {
	constructor() {
		super();
	}
}
