import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EnvironmentParams, LoggingSettings } from '@shared/models/environment-params';
import { akitaDevtools, enableAkitaProdMode } from '@datorama/akita';
import { WEBCOMPONENT_ROOT_ELEMENT_GETTER, WebComponentRootElementGetter } from '@shared/tokens';
import { type AppComponentInputs } from 'src/app/app.component';

@Injectable({
	providedIn: 'root'
})
export class AppSettingsService {
	private appSettings: EnvironmentParams;

	constructor(
		private http: HttpClient,
		@Inject(WEBCOMPONENT_ROOT_ELEMENT_GETTER) private webComponentRootElementGetter: WebComponentRootElementGetter
	) {}

	loadAppSettings(): Promise<void> {
		const baseUrl = (this.webComponentRootElementGetter() as HTMLElement & AppComponentInputs)?.staticFilesEndpoint || '';

		return this.http
			.get(baseUrl + '/assets/config/app.settings.json')
			.toPromise()
			.then((data: EnvironmentParams) => {
				this.appSettings = data;
				this.configureAkita(data.isProduction === true);
			});
	}

	get environmentParams(): EnvironmentParams {
		if (!this.appSettings) {
			throw new Error('App Settings not loaded!');
		}

		return this.appSettings;
	}

	get loggingSetting(): LoggingSettings {
		if (!this.appSettings) {
			throw new Error('App Settings not loaded!');
		}

		return this.appSettings.logging;
	}

	get retryIntervals(): number[] {
		return this.appSettings?.retryIntervals ?? [];
	}

	get isProduction(): boolean {
		const isProductionType = typeof this.appSettings?.isProduction;

		return (
			(isProductionType === 'string' && this.appSettings.isProduction === 'true') ||
			(isProductionType === 'boolean' && this.appSettings.isProduction === true)
		);
	}

	configureAkita(isProduction: boolean) {
		if (isProduction) {
			enableAkitaProdMode();

			return;
		}

		const akitaDevToolsOptions = {
			logTrace: false,
			shallow: false,
			name: 'rx-app'
		};

		akitaDevtools(akitaDevToolsOptions);
	}

	isValidConfig(key: string): boolean {
		const value = this.appSettings[key];

		if (value == null || String(value) === '' || String(value).toLowerCase().includes('replaceme')) {
			return false;
		}

		return true;
	}
}
