import { QueryEntity } from '@datorama/akita';
import { OrdersDetailsItem, OrdersDetailsState, OrdersDetailsStore } from '@modules/orders-details/state/orders-details-store';
import { Injectable } from '@angular/core';
import { ApiService, IApiCall } from '@core/services/api.service';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize, map, tap } from 'rxjs/operators';
import { LoggerService } from '@core/services/logger/logger.service';

@Injectable({ providedIn: 'root' })
export class OrdersDetailsQuery extends QueryEntity<OrdersDetailsState> {
	constructor(store: OrdersDetailsStore) {
		super(store);
	}
}

@Injectable({ providedIn: 'root' })
export class OrdersDetailsService {
	constructor(
		private store: OrdersDetailsStore,
		private query: OrdersDetailsQuery,
		private apiService: ApiService,
		private loggerService: LoggerService
	) {}

	ordersDetailsHaveBeenLoaded$ = this.query.select(s => s).pipe(map(s => !!(s.error || !s.loading)));

	getOrderDetails(orderId: number) {
		return this.query.getEntity(orderId);
	}

	loadOrdersDetails(orderIds: ReadonlyArray<number>) {
		this.store.reset();
		this.store.setLoading(true);

		return this.requestOrdersDetails(orderIds).pipe(
			tap(ordersDetails => {
				this.store.upsertMany(ordersDetails);
			}),
			catchError(error => {
				this.store.setError(error);
				this.loggerService.error('Error getting additional order details', { module: 'OrdersDetailsService', error });

				return throwError(() => error);
			}),
			finalize(() => {
				this.store.setLoading(false);
			})
		);
	}

	private requestOrdersDetails(orderIds: ReadonlyArray<number>): Observable<OrdersDetailsItem[]> {
		const apiCallOptions: IApiCall = {
			selector: 'getOrdersDetails',
			queryParams: {
				ids: orderIds
			}
		};

		return this.apiService.request(apiCallOptions);
	}
}
