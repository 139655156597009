import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { RxModel } from '@shared/models/rx-models/interfaces/rx-model';
import { RxConfiguration } from '@shared/models/rx-configuration';
import { Procedure } from '@shared/models/procedure';
import { InfoBlockInput } from '@modules/general-rx-data/models/info-block-inputs-model';
import * as flex from '@shared/utils/flex-utils';
import { IdName } from '@shared/models/id-name';
import { ScannerInfo } from '@modules/orders-details/models/scanner-info';

@Component({
	selector: 'rx-general-order-info-v1',
	templateUrl: './general-order-info-v1.component.html',
	styleUrls: ['./general-order-info-v1.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class GeneralOrderInfoV1Component {
	@Input() rx: RxModel;
	@Input() rxConfig: RxConfiguration;
	@Input() dateFormat: string;
	@Input() isResponsive = true;
	@Input() isRxForModelingContext = false;
	@Input() scannerInfo?: ScannerInfo;

	getResponsiveDirection = flex.getResponsiveDirection;
	getResponsiveWidth = flex.getResponsiveWidth;

	getFields = (isModelingContext: boolean, rx: RxModel): InfoBlockInput[] => {
		return isModelingContext
			? [
					{ name: 'GeneralRxData.Practice', id: 'general-practice', value: rx?.CompanyName },
					{ name: 'Lab.LabName', id: 'general-lab-name', value: rx?.Order?.ShipToName }
				]
			: [
					{ name: 'GeneralRxData.Practice', id: 'general-practice', value: rx?.CompanyName },
					{ name: 'GeneralRxData.Ship-to-Address', id: 'general-ship-to-address', value: rx?.ShipToAddress }
				];
	};

	getProcedureDescription = (procedureId: number, procedures: Procedure[]): string => {
		return procedures?.find(({ Id }) => Id === procedureId)?.Name ?? '';
	};

	getTypeDescription = (typeId: number, types: IdName[]): string => {
		return types?.find(({ Id }) => Id === typeId)?.Name ?? '';
	};
}
