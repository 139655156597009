import { Injectable } from '@angular/core';
import { NotesQuery } from '@modules/notes/state/notes-query';
import { OrderQuery } from '@modules/order/state/order-query';
import { ScanOptionsQuery } from '@modules/scan-options/state/scan-options-query';
import { Tooth } from '@modules/teeth-diagram/models/tooth';
import { DoctorModel } from '@shared/models/rx-models/interfaces/doctor-model';
import { OrderModel } from '@shared/models/rx-models/interfaces/order-model';
import { PatientModel } from '@shared/models/rx-models/interfaces/patient-model';
import { RxModel } from '@shared/models/rx-models/interfaces/rx-model';
import { ShellQuery } from '@shared/store/shell/shell-query';
import { Observable, of } from 'rxjs';
import { RxForDoctorApiService } from '../api/rx-for-doctor-api.service';
import { OrderFormModel } from '@modules/order/models/order-form-model';
import { v4 as uuid } from 'uuid';
import { TeethDiagramQuery } from '@modules/teeth-diagram/state/teeth-diagram-query';
import { PatientQuery } from '@modules/patient/state/patient-query';
import { LoggerService } from '@core/services/logger/logger.service';
import { RxFormCompletionLevel } from '@shared/models/rx-models/enums/rx-form-completion-level-enum';
import { SendToIdForNotLabsEnum } from '@modules/order/models/send-to-type.enum';
import { RxVersion } from '@shared/models/enums/enums';
import { RxForLabApiService } from '@modules/rx-for-lab/api/rx-for-lab-api.service';
import { map, tap } from 'rxjs/operators';
import { OrderInformationQuery } from '@modules/order-information/state/order-information.query';
import { OrderInformationModel } from '@shared/models/rx-models/interfaces/order-information-model';
import { DentureDetailsQuery } from '@modules/denture-details/state/denture-details.query';
import { DentureDetailsModel } from '@shared/models/rx-models/interfaces/denture-details-model';
import { DentureDetailsUtils } from '@modules/denture-details/utils/denture-details-utils';
import { ApplianceDetailsQuery } from '@modules/appliance-details/state/appliance-details.query';
import { ApplianceDetailsModel } from '@shared/models/rx-models/interfaces/appliance-details-model';
import { ApplianceDetailsUtils } from '@modules/appliance-details/utils/appliance-details-utils';
import { getTeethNumberingSystem } from '@shared/utils/teeth-numbering-system-util';
import { TreatmentStageEnum } from '@modules/order/models/treatment-stage.enum';
import { BridgeService } from '@shared/services/bridge.service';
import { AlignTechNotesQuery } from '@modules/aligntech-notes/state/aligntech-notes-query';
import { DateOfBirthUtils } from '@shared/utils/date-of-birth-utils';
import { RoleTypeEnum } from '@shared/models/role-type';
import { ShellStore } from '@shared/store/shell/shell-store';
import { HostPlatformService } from '@shared/services/host-platform.service';
import { TracesQuery } from '@shared/store/traces/traces-query';
import { ReadOnlyRulesService } from '@shared/services/readOnlyRules.service';
import { ValidateRxService } from '@shared/services/validate-rx';
import {BiLoggerService} from "@core/services/logger/bi-logger.service";

@Injectable({
	providedIn: 'root'
})
export class SaveRxService {
	private name = 'SaveRxService';
	constructor(
		private shellQuery: ShellQuery,
		private notesQuery: NotesQuery,
		private orderQuery: OrderQuery,
		private scanOptionsQuery: ScanOptionsQuery,
		private teethDiagramQuery: TeethDiagramQuery,
		private patientQuery: PatientQuery,
		private orderInformationQuery: OrderInformationQuery,
		private doctorApi: RxForDoctorApiService,
		private labApi: RxForLabApiService,
		private logger: LoggerService,
		private biLogger: BiLoggerService,
		private dentureDetailsQuery: DentureDetailsQuery,
		private applianceDetailsQuery: ApplianceDetailsQuery,
		private bridgeService: BridgeService,
		private alignTechNotesQuery: AlignTechNotesQuery,
		private shellStore: ShellStore,
		private hostPlatformService: HostPlatformService,
		private tracesQuery: TracesQuery,
		private readOnlyRulesService: ReadOnlyRulesService,
		private validateRxService: ValidateRxService
	) {}

	saveRx(): Observable<RxModel> {
		const rx = this.prepareRxModel(this.shellQuery.rx);

		if (this.readOnlyRulesService.isReadOnlyOrHaveReadOnlyRules()) {
			return of(rx);
		}

		this.biLogger.sendSaveRxBiLogs(rx);

		this.logger.logRXData(rx, 'Saving rx-app data:', this.name, { traces: this.tracesQuery.savingGettingRxTraceIds });
		if (this.shellQuery.isRxForLab) {
			const orderId = this.shellQuery.orderId;
			const showLoader = !this.hostPlatformService.isIteroLab;

			return this.labApi.saveRx(rx, orderId, showLoader).pipe(map(response => response.Result as RxModel));
		}

		return this.doctorApi.saveRx(rx).pipe(
			tap(rxModel => {
				if (!rxModel) {
					return;
				}

				if (typeof rxModel === 'string') {
					rxModel = JSON.parse(rxModel);
				} else {
					rxModel = rxModel.Result;
				}

				if (!!rxModel) {
					this.shellStore.update({ rx: rxModel });
				} else {
					this.logger.info(`Couldn't parse response from SaveRx for rxId: ${rx.ID}`, { module: this.name });
				}
			})
		);
	}

	// TODO: add more strict types
	// eslint-disable-next-line @typescript-eslint/ban-types
	removeRxV1Properties(rx: object, rxv1fields: string[]): object {
		for (const property in rx) {
			if (rx.hasOwnProperty(property)) {
				if (typeof rx[property] === 'object' && rx[property] !== null) {
					this.removeRxV1Properties(rx[property], rxv1fields);
				} else {
					if (rxv1fields.includes(property)) {
						delete rx[property];
					}
				}
			}
		}

		return rx;
	}

	getOrderInformationModel(): OrderInformationModel {
		return this.orderInformationQuery.getOrderInformationForSave();
	}

	prepareRxModel(existingRx: RxModel): RxModel {
		// eslint-disable-next-line @typescript-eslint/naming-convention
		const { MultiBiteScan, TreatmentStage, AlignerNumber } = this.getOrderRelatedData();

		// eslint-disable-next-line @typescript-eslint/naming-convention
		const { NIRIEnabled, PrePrepScan, IsSleeveConfirmed, EmergenceProfile, MultiBiteRestoScan, PalatalScanningFeedback } =
			this.getScanOptions(existingRx);
		const { contactId, companyId } = this.getContactAndCompanyIds(existingRx);

		if (existingRx && (existingRx.CompanyID !== companyId || existingRx.ContactID !== contactId)) {
			this.logger.info(
				`Rx ContactId and CompanyId were changed. Previous ContactId: ${existingRx.ContactID},
				new ContactId: ${contactId}. Previous CompanyId: ${existingRx.CompanyID}, new CompanyId: ${companyId}`,
				{ module: this.name }
			);
		}

		let rx: RxModel = {
			ID: this.getRxId(existingRx),
			CompanyID: companyId,
			ContactID: contactId,
			Patient: this.getPatientModel() || existingRx?.Patient,
			Order: this.getOrderModel(),
			Doctor: this.getDoctorModel(),
			Notes: this.notesQuery.notes,
			Teeth: this.getRxTeethModels(existingRx),
			Bridges: this.getLegacyBridges(),
			CompletionLevel: this.getCompletionLevel(),
			ShadeSystemId: this.shellQuery.userSettings?.ShadeSystemId,
			Signature: existingRx?.Signature || '',
			ClonedFromRxID: this.shellQuery.clonedFromRxID,
			TechnicalNotes: existingRx?.TechnicalNotes || null,
			PrePrepScan,
			MultiBiteScan,
			AligntechNotes: this.alignTechNotesQuery.alignTechNotes || '',
			OrderInformation: this.getOrderInformationModel(),
			TreatmentStage: TreatmentStage || null,
			AlignerNumber,
			HasNIRI: existingRx?.HasNIRI || false,
			// BillToAddress: '', // TODO figure out in future empty for now
			// ShipToAddress: '', // TODO figure out in future empty for now
			// PayerAddress: '', // TODO figure out in future empty for now
			CompanyName: existingRx ? existingRx.CompanyName : this.shellQuery?.rx?.CompanyName,
			ForceRxFormUpdate: existingRx?.ForceRxFormUpdate || false,
			NIRIEnabled,
			NIRIhasOnlyIOC: existingRx?.NIRIhasOnlyIOC || false,
			ToothNumberingSystem: getTeethNumberingSystem({
				teethNumberingSystemFromUserSettings: this.shellQuery.userSettings?.ToothId,
				teethNumberingSystemFromRx: existingRx?.ToothNumberingSystem,
				isDoctor: this.shellQuery.userRole === RoleTypeEnum.Doctor,
				logger: this.logger
			}),
			IsSleeveConfirmed,
			Version: this.shellQuery.isProcedureFlow ? this.shellQuery.rxVersionFlow : RxVersion.CaseTypeFlow,
			DentureDetails: this.getDentureDetails(),
			ApplianceDetails: this.getApplianceDetails(),
			IsDraft: this.orderQuery.isDraftSelected,
			EmergenceProfile,
			MultiBiteRestoScan: {
				Enabled: !!(MultiBiteRestoScan?.Enabled && MultiBiteRestoScan?.Includes?.length),
				Includes: MultiBiteRestoScan?.Includes || []
			},
			PalatalScanningFeedback
		};

		if (rx.Version === RxVersion.CaseTypeFlow) {
			const rxv1fields = [
				'DentureDetails',
				'ApplianceDetails',
				'SpecificationId',
				'ProcedureId',
				'ProcedureTypeId',
				'ProcedureMapId',
				'PonticDesignID',
				'ImplantBasedRestorationTypeId'
			];

			rx = this.removeRxV1Properties(rx, rxv1fields) as RxModel;
		}

		if (this.shellQuery.userRole !== RoleTypeEnum.Doctor) {
			// Make sure that the date string is full (E.G. 2022-06-09, important for iTero Modeling)
			rx.Patient = DateOfBirthUtils.makePatientDateOfBirthFull(rx.Patient);
		}

		return rx;
	}

	private getContactAndCompanyIds(rx: RxModel): { contactId: number; companyId: number | string } {
		let contactId: number;
		let companyId: number | string | undefined;

		// if rx opened from OrthoCAD then use logic which was done inside old RxUi:
		// set contactId and companyId from the context.
		if (this.shellQuery.userRole !== RoleTypeEnum.Doctor && this.hostPlatformService.isOrthoCad) {
			if (this.shellQuery.contactId > 0) {
				contactId = this.shellQuery.contactId;
			} else {
				// if some edge case and context contactId is invalid use value from rx.
				contactId = rx?.ContactID > 0 ? rx?.ContactID : rx?.Doctor?.Id;
			}
			companyId = this.shellQuery.companyId > 0 ? this.shellQuery.companyId : rx?.CompanyID;

			return { contactId, companyId };
		}

		// For other cases use the same logic which was done inside RxApp from the beginning and already worked since 2021.
		contactId = rx?.ContactID > 0 ? rx?.ContactID : this.shellQuery.contactId;
		companyId = (rx?.CompanyID as number) > 0 ? rx?.CompanyID : this.shellQuery.companyId;

		return { contactId, companyId };
	}

	private getScanOptions(existingRx: RxModel): Partial<RxModel> {
		return (this.shellQuery.isRxForLab || this.hostPlatformService.isIteroModeling) && existingRx
			? {
					NIRIEnabled: existingRx.NIRIEnabled,
					PrePrepScan: existingRx.PrePrepScan,
					IsSleeveConfirmed: existingRx.IsSleeveConfirmed,
					EmergenceProfile: existingRx.EmergenceProfile,
					MultiBiteRestoScan: existingRx.MultiBiteRestoScan,
					PalatalScanningFeedback: existingRx.PalatalScanningFeedback
				}
			: {
					NIRIEnabled: this.scanOptionsQuery.isNiriCaptureChecked,
					PrePrepScan: this.scanOptionsQuery.isPreTreatmentChecked,
					IsSleeveConfirmed: this.scanOptionsQuery.isSleeveChecked.value,
					EmergenceProfile: this.scanOptionsQuery.isEmergenceProfileChecked,
					PalatalScanningFeedback: this.scanOptionsQuery.isPalatalFeedbackChecked,
					MultiBiteRestoScan: {
						Enabled: this.scanOptionsQuery.isRestoMultiBiteSelected,
						Includes: Object.keys(this.scanOptionsQuery.additionalBites).filter(
							key => this.scanOptionsQuery.additionalBites[key]
						)
					}
				};
	}

	private getPatientModel(): PatientModel {
		return this.patientQuery.patient;
	}

	private getRxId(existingRx: RxModel): string {
		if (existingRx?.ID) {
			return existingRx?.ID;
		} else if (this.shellQuery.preparedNewRxId) {
			return this.shellQuery.preparedNewRxId;
		} else {
			return uuid();
		}
	}

	// After the user sends rx
	private getOrderModel(): OrderModel {
		if (this.shellQuery.isRxForLab) {
			return this.shellQuery.rx.Order;
		}

		// eslint-disable-next-line @typescript-eslint/naming-convention
		const { CaseTypeId, DueDate, ShipToId, ShipToName, DirectToLab, ProcedureId, ProcedureMapId, ProcedureTypeId } =
			this.orderQuery.orderModelForSave;
		const existingRx = this.shellQuery.rx;
		const { shipToId, shipToName } = this.getShipToData(ShipToId, ShipToName);

		const orderModel = {
			CaseTypeId,
			DueDate,
			ShipToId: shipToId,
			ShipToName: shipToName,
			ID: this.shellQuery.orderId ?? 0,
			Code: existingRx?.Order?.Code || '',
			Status: existingRx?.Order?.Status || '',
			State: existingRx?.Order?.State || '',
			ScanDate: existingRx?.Order?.ScanDate || '',
			DirectToLab,
			IsBracketsPresent: existingRx?.Order?.IsBracketsPresent || false,
			HasNIRI: existingRx?.Order?.HasNIRI || false,
			NIRIhasOnlyIOC: existingRx?.Order?.NIRIhasOnlyIOC || false
		};

		const procedureData = {
			ProcedureId,
			ProcedureMapId,
			ProcedureTypeId
		};

		const hasProcedureData = Object.keys(procedureData).every(procedureDataItem => typeof procedureDataItem !== 'undefined');

		if (hasProcedureData) {
			Object.assign(orderModel, procedureData);
		} else {
			this.logger.info('No procedure map data!!!', {
				module: this.name
			});
		}

		return orderModel;
	}

	private getShipToData(shipToId: number, shipToName: string): { shipToId: number; shipToName: string } {
		if (Object.values(SendToIdForNotLabsEnum).includes(shipToId)) {
			return { shipToId: -1, shipToName: null };
		}

		return { shipToId, shipToName };
	}

	private getDoctorModel(): DoctorModel {
		// TODO: load doctor data from rx-configuration
		return this.shellQuery.doctor as DoctorModel;
	}

	private getRxTeethModels(existingRx: RxModel): Tooth[] {
		if (this.shellQuery.isRxForLab && this.readOnlyRulesService.isTeethDiagramReadOnly) {
			return existingRx.Teeth?.map(tooth => this.teethDiagramQuery.getToothBackendModel(tooth));
		}

		return this.teethDiagramQuery.treatedTeethModel;
	}

	private getCompletionLevel(): RxFormCompletionLevel {
		if (this.shellQuery.isRxForLab) {
			return this.shellQuery.rx.CompletionLevel;
		}

		let completionLevel = RxFormCompletionLevel.Incomplete;
		const isRxValidForSave = this.shellQuery.isRxValidForSave;

		if (isRxValidForSave) {
			const isRxValidForSend = this.orderQuery.isValidForSendOrderData && this.teethDiagramQuery.isValidForSendTreatedTeeth;

			const isDentureDetailsValidForSend = DentureDetailsUtils.isDentureDetailsEnabled(this.orderQuery.procedureMap?.ProcedureId)
				? this.validateRxService.isDentureDetailsValidForSend(this.dentureDetailsQuery)
				: true;

			completionLevel =
				isRxValidForSend && isDentureDetailsValidForSend ? RxFormCompletionLevel.ReadyForSend : RxFormCompletionLevel.ReadyForScan;
		}

		return completionLevel;
	}

	private getDentureDetails(): DentureDetailsModel {
		if (this.shellQuery.isRxForLab) {
			return this.shellQuery.rx?.DentureDetails ?? null;
		}
		if (!DentureDetailsUtils.isDentureDetailsEnabled(this.orderQuery.procedureMap?.ProcedureId)) {
			return null;
		}

		const dentureDetails = this.dentureDetailsQuery.getValue();

		return {
			Stage: dentureDetails.stage?.Id,
			Mould: dentureDetails.mould?.Id,
			UpperJaw: dentureDetails.isUpperJawChecked,
			LowerJaw: dentureDetails.isLowerJawChecked,
			ShadeSystemId: dentureDetails.shadeSystem?.Id,
			TeethShade: dentureDetails.teethShade,
			GingivalShadeId: dentureDetails.gingivalShade?.Id,
			IsDentureCopyScan: dentureDetails.isDentureCopyScan
		};
	}

	private getApplianceDetails(): ApplianceDetailsModel {
		if (this.shellQuery.isRxForLab) {
			return this.shellQuery.rx?.ApplianceDetails ?? null;
		}
		if (!ApplianceDetailsUtils.isApplianceDetailsEnabled(this.orderQuery.procedureMap?.ProcedureId)) {
			return null;
		}

		const applianceDetails = this.applianceDetailsQuery.getValue();

		return {
			UpperJaw: applianceDetails.isUpperChecked,
			LowerJaw: applianceDetails.isLowerChecked,
			ApplianceType: applianceDetails.applianceType.Id
		};
	}

	private getOrderRelatedData(): { MultiBiteScan: boolean; TreatmentStage: TreatmentStageEnum; AlignerNumber: string } {
		const extractRequiredPropsFromModel = (model: RxModel | OrderFormModel) => {
			// eslint-disable-next-line @typescript-eslint/naming-convention
			const { MultiBiteScan, TreatmentStage, AlignerNumber } = model;

			return { MultiBiteScan, TreatmentStage, AlignerNumber };
		};

		if (this.shellQuery.isRxForLab) {
			return extractRequiredPropsFromModel(this.shellQuery.rx);
		} else {
			return extractRequiredPropsFromModel(this.orderQuery.orderModelForSave);
		}
	}

	private getLegacyBridges() {
		const { upperJaw, lowerJaw } = this.teethDiagramQuery.getValue().teeth;

		const allBridges = this.bridgeService.getAllBridges({ upperJaw, lowerJaw });
		const legacyBridges = [];

		for (const bridgeIndex of Object.keys(allBridges)) {
			const bridge: Tooth[] = (allBridges[bridgeIndex] as Tooth[])?.map(tooth =>
				this.teethDiagramQuery.getToothWithBackendValues(tooth, true)
			);
			const fromTooth = bridge[0];
			const toTooth = bridge.pop();
			const legacyBridge = {
				FromToothID: fromTooth.ToothID,
				ToToothID: toTooth.ToothID,
				BridgeTypeId: 1,
				BridgeIndex: bridge[0].BridgeIndex,
				FromAdaID: fromTooth.ToothID,
				ToAdaID: toTooth.ToothID,
				fromTooth,
				toTooth
			};

			legacyBridges.push(legacyBridge);
		}

		return legacyBridges;
	}
}
