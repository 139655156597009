import { Injectable } from '@angular/core';
import { RxModel } from '@shared/models/rx-models/interfaces/rx-model';
import { AppSettingsService } from '../app-settings/app-settings.service';
import { HostPlatformService } from '@shared/services/host-platform.service';
import { Timber } from '@itero/timber';
import { EnvironmentParams } from '@shared/models/environment-params';
import { AppenderType, LogLevel } from '@itero/timber/enums';
import { v4 as uuid } from 'uuid';
import { BiEvent, ISessionStartEvent, IStageReportEvent, ITags, IUserActionEvent } from './interfaces/bi.events';
import { BiEventType, BiObject, BiObjectName, BiStage } from './interfaces/bi.enums';
import { SessionType } from '@shared/models/consts';
import { IRemoteConfig } from '@itero/timber/interfaces/config.remote';
import { AuthInfoQuery } from '@shared/store/authInfo/auth-info-query';

@Injectable({ providedIn: 'root' })
export class BiLoggerService {
	protected biTimber: Timber;
	private rxSessionId: string;

	constructor(
		private appSettingsService: AppSettingsService,
		private authInfoQuery: AuthInfoQuery,
		protected hostPlatformService: HostPlatformService
	) {
		this.createBi(appSettingsService.environmentParams);
	}

	static getConfig(environmentParams: EnvironmentParams): IRemoteConfig {
		if (!environmentParams) {
			return null;
		}

		const { appId, requiredFields, eventType } = environmentParams.logging;

		return {
			url: environmentParams.biTimberUrl,
			appId,
			requiredFields,
			minLogLevel: LogLevel.All,
			appenderTypes: [AppenderType.BIRemote],
			eventType
		} as IRemoteConfig;
	}

	sendSaveRxBiLogs(rx: RxModel): void {
		const { IsDraft: isDraft, MultiBiteRestoScan: multiBiteRestoScan } = rx;

		this.stageReportEvent({
			object: BiObject.Attachments,
			stage: BiStage.Save,
			previous: 'draft',
			description: BiObjectName.IsDraft,
			trigger: String(!!isDraft)
		});

		this.stageReportEvent({
			object: BiObject.ScanOptions,
			stage: BiStage.Save,
			previous: 'draft',
			description: multiBiteRestoScan?.Includes?.join(', ') || '',
			trigger: ''
		});
	}

	sessionStartEvent(rxId: string, user: number, companyId: number, rxSessionId?: string, orderId?: number): void {
		this.rxSessionId = rxSessionId;
		this.sendBiEvent({
			event: {
				externalSessionId: this.authInfoQuery.authInfo?.sessionId ?? uuid(),
				tags: {
					userId: String(user),
					companyId: String(companyId),
					rxId,
					orderId: orderId ? String(orderId) : null
				} as ITags
			} as ISessionStartEvent,
			type: BiEventType.SessionStart
		});
	}

	userActionEvent(event: IUserActionEvent): void {
		this.sendBiEvent({
			event,
			type: BiEventType.UserAction
		} as BiEvent);
	}

	stageReportEvent(event: IStageReportEvent): void {
		this.sendBiEvent({
			event,
			type: BiEventType.StageReport
		} as BiEvent);
	}

	protected createBi(environmentParams: EnvironmentParams): void {
		if (this.appSettingsService.isValidConfig('biTimberUrl')) {
			this.biTimber = new Timber(BiLoggerService.getConfig(environmentParams));
		} else {
			this.biTimber = null;
			/* eslint-disable no-console */
			console.error('BI object was not created');
		}
	}

	protected sendBiEvent(biEvent: BiEvent) {
		const stringEvent = JSON.stringify(biEvent.event);

		if (this.rxSessionId) {
			this.biTimber?.biLog(
				LogLevel.All,
				{},
				{
					extendedParameters: {
						sessionId: this.rxSessionId,
						sessionType: this.hostPlatformService.isScanner ? SessionType.Desktop : SessionType.Web,
						component: 'RX',
						type: biEvent.type,
						additionalFields: JSON.parse(stringEvent)
					}
				}
			);
		}
	}
}
