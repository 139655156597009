import { Pipe, PipeTransform } from '@angular/core';

export const SCANNER_TYPE_FOR_PRINT = {
	Unknown: '',
	ElementRTH: 'Element RTH',
	Element5D: 'Element 5D',
	Lumina: 'Lumina'
};

@Pipe({
	name: 'scannerModel',
	standalone: true
})
export class ScannerModelPipe implements PipeTransform {

	transform(scannerModel: string): string {
		if (typeof scannerModel !== 'string') {
			return SCANNER_TYPE_FOR_PRINT.Unknown;
		}

		const lowerCaseModel = scannerModel.toLowerCase();

		const KEYWORD_5D = '5d';
		const KEYWORD_FLEX = 'flex';
		const KEYWORD_LUMINA = 'lumina';
		const KEYWORD_ELEMENT = 'element';

		if (lowerCaseModel.includes(KEYWORD_5D)) {
			return SCANNER_TYPE_FOR_PRINT.Element5D;
		} else if (lowerCaseModel.includes(KEYWORD_LUMINA)) {
			return SCANNER_TYPE_FOR_PRINT.Lumina;
		} else if (lowerCaseModel.includes(KEYWORD_ELEMENT) || lowerCaseModel.includes(KEYWORD_FLEX)) {
			return SCANNER_TYPE_FOR_PRINT.ElementRTH;
		}

		return SCANNER_TYPE_FOR_PRINT.Unknown;
	}
}
