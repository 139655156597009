import { HttpClient, HttpClientModule } from '@angular/common/http';
import { MatIconModule } from '@angular/material/icon';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslationLoaderService } from '@core/services/translation-loader.service';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { defaultLanguageCode } from '@shared/models/consts';
import { ShellQuery } from '@shared/store/shell/shell-query';

export const rootImportedModules = [
	BrowserModule,
	BrowserAnimationsModule,
	MatIconModule,
	HttpClientModule,
	TranslateModule.forRoot({
		defaultLanguage: defaultLanguageCode,
		loader: {
			provide: TranslateLoader,
			useClass: TranslationLoaderService,
			deps: [HttpClient, ShellQuery]
		},
		extend: true
	})
];
