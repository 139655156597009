import { DoBootstrap, NgModule } from '@angular/core';
import { AppComponent } from 'src/app/app.component';
import { AppModule } from 'src/app/app.module';
import { rootImportedModules } from '../root-imported-modules';
import { provideRootProviders } from '../root-providers';

@NgModule({
	imports: [...rootImportedModules, AppModule],
	providers: provideRootProviders({
		componentTag: 'rx-app',
		component: AppComponent
	})
})
export class DirectBootstrapModule implements DoBootstrap {
	ngDoBootstrap(): void {}
}
