import { Component, ElementRef, Inject, InjectionToken, OnInit } from '@angular/core';
import { PrintRxModel, RxModel } from '@shared/models/rx-models/interfaces/rx-model';
import { take, takeUntil, tap } from 'rxjs/operators';
import { isLegacyRestorative, isResorative } from '@shared/utils/restorative-check-util';
import { PrintOrientation, RxVersion } from '@shared/models/enums/enums';
import { combineLatest, Subject } from 'rxjs';
import { BaseDestroyableComponent } from '@shared/base-classes/base-destroyable';
import { ShellQuery } from '@shared/store/shell/shell-query';
import { DentureDetailsUtils } from '@modules/denture-details/utils/denture-details-utils';
import { NotesQuery } from '@modules/notes/state/notes-query';
import { PrintService } from '@shared/services/print.service';
import { ProcedureEnum } from '@core/procedure-helpers/models/procedure.enum';
import { ApplianceDetailsUtils } from '@modules/appliance-details/utils/appliance-details-utils';
import { OrdersDetailsService } from '@modules/orders-details/state/orders-details-service';
import { ScannerInfo } from '@modules/orders-details/models/scanner-info';

export const PRINT_IFRAME_ELEMENT = new InjectionToken<HTMLIFrameElement>('Token to resolve print iframe element');

@Component({
	selector: 'print-rx',
	templateUrl: './print-rx.component.html'
})
export class PrintRxComponent extends BaseDestroyableComponent implements OnInit {
	isRestorativeCheck = isResorative;
	isLegacyRestorativeCheck = isLegacyRestorative;

	rxs: PrintRxModel[];

	rxsReadyCounter = 0;

	orientation: PrintOrientation;
	logoLoaded = new Subject<void>();
	imagesLoaded = new Subject<void>();
	prinStyleCss: string;

	printOrientation = {
		landscape: PrintOrientation.landscape,
		portrait: PrintOrientation.portrait
	};

	logoSrc = '';
	anchorClasses = ['.teeth-title', '.treatment-information-title', '.bridge-information-title', '.notes-title'];

	constructor(
		private shellQuery: ShellQuery,
		private notesQuery: NotesQuery,
		private elementRef: ElementRef,
		@Inject(PRINT_IFRAME_ELEMENT) public printIframeElement: HTMLIFrameElement,
		private printService: PrintService,
		private ordersDetailsService: OrdersDetailsService
	) {
		super();
	}

	rxConfiguration$ = this.shellQuery.rxConfiguration$;
	ordersDetailsHaveBeenLoaded$ = this.ordersDetailsService.ordersDetailsHaveBeenLoaded$;

	checkIsProcedureFlow = (rxVersion: RxVersion) => this.shellQuery.checkIsProcedureFlow(rxVersion);

	isAnyScanOptionPresented = (rx: RxModel) =>
		rx.PrePrepScan || rx.EmergenceProfile || rx?.DentureDetails?.IsDentureCopyScan || rx?.MultiBiteRestoScan?.Enabled;

	ngOnInit(): void {
		this.logoSrc = `${this.shellQuery.staticFilesEndpoint}/assets/logos/iTeroLogo.svg`;
		this.orientation = this.shellQuery.printOrientation ?? PrintOrientation.portrait;

		const rxsForPrint = this.shellQuery.rxsForPrint.map(rxs => ({ ...rxs }));

		if (this.shellQuery.rx?.ID) {
			const currentRx = rxsForPrint.find(rx => rx.ID === this.shellQuery.rx.ID);

			if (!!currentRx) {
				currentRx.Notes = this.notesQuery.notes; // mutation
			}
		}

		this.rxs = this.presetOrientationPrintRules(rxsForPrint);
		this.rxsReadyCounter = this.rxs?.filter(rx => rx.isRestorativeOrLegacyCheck).length;
		this.subscribeToPrintProcess().pipe(takeUntil(this.componentAlive$)).subscribe();

		if (this.rxsReadyCounter < 1) {
			this.imagesLoaded.next();
		}

		this.ordersDetailsService
			.loadOrdersDetails(this.rxs.map(rx => rx.rxModel.Order.ID))
			.pipe(takeUntil(this.componentAlive$))
			.subscribe();
	}

	onImagesLoaded() {
		this.rxsReadyCounter--;

		if (this.rxsReadyCounter < 1) {
			this.imagesLoaded.next();
		}
	}

	subscribeToPrintProcess() {
		return combineLatest([this.imagesLoaded, this.logoLoaded]).pipe(
			take(1),
			tap(() => {
				if (this.shellQuery.autoDisplayPrintViewInTheDOM) {
					this.autoDisplayPrintViewInTheDOM(this.printIframeElement);
				} else {
					this.setPageOrientation();
					this.printIframeElement.contentWindow.focus();

					this.printIframeElement.contentWindow.print();

					this.printIframeElement.contentDocument.body.removeChild(this.elementRef.nativeElement);
					this.printIframeElement.remove();
				}
			})
		);
	}

	presetOrientationPrintRules(rxs: RxModel[]): PrintRxModel[] {
		const isLandscape = this.orientation === this.printOrientation.landscape;

		return rxs.map(rx => {
			const isRestorativeOrLegacyCheck =
				this.isRestorativeCheck({ caseTypeId: rx.Order.CaseTypeId }) ||
				this.isLegacyRestorativeCheck({ caseTypeId: rx.Order.CaseTypeId });

			if (isLandscape) {
				return {
					rxModel: rx,
					isRestorativeOrLegacyCheck
				};
			}

			return {
				rxModel: rx,
				isRestorativeOrLegacyCheck
			};
		});
	}

	isApplianceSectionVisible(rxs: RxModel): boolean {
		return ApplianceDetailsUtils.isApplianceDetailsEnabledForLabAndPrintRx(
			rxs?.Order?.ProcedureId as ProcedureEnum,
			rxs?.ApplianceDetails?.UpperJaw,
			rxs?.ApplianceDetails?.LowerJaw,
			rxs?.ApplianceDetails?.ApplianceType
		);
	}

	onLogoLoaded() {
		this.logoLoaded.next();
	}

	isDentureDetailsVisible(procedureId: number): boolean {
		return DentureDetailsUtils.isDentureDetailsEnabled(procedureId);
	}

	setPageOrientation() {
		if (this.orientation === PrintOrientation.landscape) {
			const css = '@page { size: landscape; }';
			const style = document.createElement('style');

			style.type = 'text/css';
			style.media = this.printService.getStyleName();

			style.appendChild(document.createTextNode(css));
			this.printIframeElement.contentWindow.document.head.appendChild(style);
		}
	}

	getScannerInfo(rx: RxModel): ScannerInfo {
		return this.ordersDetailsService.getOrderDetails(rx.Order.ID)?.scannerInfo;
	}

	private autoDisplayPrintViewInTheDOM(printIframeElement: HTMLIFrameElement): void {
		printIframeElement.style.visibility = 'visible';
		printIframeElement.style.position = 'unset';
		printIframeElement.style.zIndex = '10';
		printIframeElement.style.width = '877px'; // for WEB
		printIframeElement.style.height = '1240px'; // for WEB

		const nonPrintView = document.querySelector<HTMLElement>('rx-app .main-layout');

		nonPrintView.style.display = 'none';
	}
}
