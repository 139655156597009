<div class="rectangle-container">
    <div fxLayout="column">
        <div class="general-info rx-card-title-print">
            <strong>{{ 'DoctorSection.Doctor' | translate }}:</strong>
            <span>{{ rxForPrint.Doctor?.Name | doubleHyphen }}</span>
        </div>
        <rx-general-order-info-v1
            class="general-info-body border-box"
            [rx]="rxForPrint"
            [rxConfig]="rxConfiguration$ | async"
            [dateFormat]="dateFormat$ | async"
            [isResponsive]="false"
			[scannerInfo]="scannerInfo"
        ></rx-general-order-info-v1>
    </div>
</div>
