import { Component } from '@angular/core';
import { SpinnerService } from '@core/services/spinner.service';
import { NgIf } from '@angular/common';
import { SpinnerElementComponent } from "../spinner-element/spinner-element.component";

@Component({
	// tslint:disable-next-line: component-selector
	selector: 'rx-spinner',
	template: '<rx-spinner-element *ngIf="active"></rx-spinner-element>',
	standalone: true,
	imports: [NgIf, SpinnerElementComponent]
})
export class SpinnerComponent {
	active = false;

	constructor(private spinner: SpinnerService) {
		this.spinner.onActiveChanged.subscribe((isActive: boolean) => {
			this.active = isActive;
		});
	}
}
