import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { RxForDoctorModule } from '@modules/rx-for-doctor/rx-for-doctor.module';
import { RxForLabModule } from '@modules/rx-for-lab/rx-for-lab.module';
import { PrintRxModule } from '@modules/print-rx/print-rx.module';
import { RxForModelingModule } from '@modules/rx-for-modeling/rx-for-modeling.module';
import { SharedModule } from '@shared/shared.module';
import { CommonModule } from '@angular/common';

@NgModule({
	declarations: [AppComponent],
	imports: [CommonModule, SharedModule, RxForDoctorModule, RxForLabModule, RxForModelingModule, PrintRxModule],
	exports: [AppComponent]
})
export class AppModule {}
