import { Component } from '@angular/core';

@Component({
	// tslint:disable-next-line: component-selector
	selector: 'rx-spinner-element',
	templateUrl: './spinner-element.component.html',
	styleUrls: ['./spinner-element.component.scss'],
	standalone: true
})
export class SpinnerElementComponent {}
