import {
	AfterViewInit,
	Component,
	ComponentFactoryResolver,
	EventEmitter,
	HostListener,
	Injector,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	Output,
	Renderer2,
	SimpleChanges,
	ViewChild,
	ViewContainerRef,
	ViewEncapsulation
} from '@angular/core';
import { AppFacade } from './app.facade';
import { fromEvent, merge, Observable, of, Subject } from 'rxjs';
import { catchError, delay, exhaustMap, filter, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { BaseDestroyableComponent } from '@shared/base-classes/base-destroyable';
import { SaveRxService } from '@modules/rx-for-doctor/services/save-rx.service';
import { TranslateService, TranslationChangeEvent } from '@ngx-translate/core';
import { resetStores } from '@datorama/akita';
import { RxModel } from '@shared/models/rx-models/interfaces/rx-model';
import { LoggerService } from '@core/services/logger/logger.service';
import { RxComponents } from '@shared/models/rx-models/interfaces/rx-components-model';
import { RoleTypeEnum } from '@shared/models/role-type';
import { debounceDelay, emptyGuid, stayInRx } from '@shared/models/consts';
import { MatIconService } from '@shared/services/mat-icons.service';
import { PatientQuery } from '@modules/patient/state/patient-query';
import { localSettingsStoreName } from '@shared/store/localSettings/local-settings-store';
import { PrintOrientation } from '@shared/models/enums/enums';
import { ShellStore } from '@shared/store/shell/shell-store';
import { RxNote } from '@modules/notes/models/rx-note';
import { NotesService } from '@modules/notes/services/notes.service';
import { PRINT_IFRAME_ELEMENT, PrintRxComponent } from '@modules/print-rx/containers/print-rx/print-rx.component';
import { ShellQuery } from '@shared/store/shell/shell-query';
import { OrderInformationModel } from '@shared/models/rx-models/interfaces/order-information-model';
import { NotesQuery } from '@modules/notes/state/notes-query';
import { RxRulesService } from '@shared/services/rx-rules-helper/rx-rules.service';
import { Version as rxAppVersion } from '../environments/app-version';
import { PatientAppIframeCommunicationService } from '@modules/patient/services/patient-app-iframe-communication.service';
import { ForceSaveToothEditorService } from '@shared/services/force-save-tooth-editor.service';
import { ToothEditorQuery } from '@modules/tooth-editor/state/tooth-editor-query';
import { ChangeUnitTypeInput } from '@shared/models/change-unit-type-input';
import { PrintService } from '@shared/services/print.service';
import { AligntechNotesService } from '@modules/aligntech-notes/services/aligntech-notes.service';
import { TeethNumberingSystem } from '@modules/teeth-diagram/models/teeth-numbering-system.enum';
import { TeethUnitType } from '@shared/models/rx-rules-json-interface';
import { HostPlatformService } from '@shared/services/host-platform.service';
import { AuthInfo } from '@shared/models/auth-info';
import { RxAttachmentsApiService } from '@shared/rx-attachments/rx-attachments-api.service';
import { TracesQuery } from '@shared/store/traces/traces-query';
import { ReadOnlyRulesService } from '@shared/services/readOnlyRules.service';
import { FontFaceService } from '@core/services/font-face.service';
import { v4 as uuid } from 'uuid';

type RxSavedPayload = typeof stayInRx | undefined | RxModel; // LOL

export interface AppComponentInputs {
	orderId: number;
	rxId: string;
	companyId: number;
	doctorId: number;
	contactId: number;
	languageCode: string;
	apiEndpoint: string;
	scannerId: string;
	runMode: string;
	clientVersion: string;
	packageVersion: string;
	isReadOnly: boolean;
	readOnlyRules: RxComponents;
	applicationMode: string;
	productType: string;
	isRxTakenForScan: boolean;
	authToken: string;
	validationMode: string;
	patientGuid: string;
	clonedFromRxId: string;
	userRole: RoleTypeEnum;
	rxIdsForPrint: number[];
	triggerPrintRx: boolean;
	orientation: string;
	forceV0: boolean;
	staticFilesEndpoint: string;
	isReturn: boolean;
	shouldAnonymizeRx: boolean;
	autoDisplayPrintViewInTheDOM: boolean;
	isHeadlessPrint: boolean;
	enableAllCaseTypesForAddRx: boolean;
	shouldCheckSleeveConfirmationCheckBox: boolean;
	forceDisplayTeethNumberingSystem: TeethNumberingSystem;
	printStylesName: string;
	authInfo: AuthInfo;
	rxAppApiEndpoint: string;
	patientAppApiEndpoint: string;
	saveRx: string;
	saveRxScanner: string;
	validateBeforeSend: string;
	addNote: RxNote;
	changeUnitType: ChangeUnitTypeInput;
	orderInformation: OrderInformationModel;
	prepareRx: number;
	getUnitType: number;
}

@Component({
	selector: 'rx-app',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
	encapsulation: ViewEncapsulation.None,
	providers: [AppFacade, PrintService],
	// tslint:disable-next-line:no-host-metadata-property
	// eslint-disable-next-line @angular-eslint/no-host-metadata-property
	host: { class: 'mat-typography' }
})
export class AppComponent extends BaseDestroyableComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
	@ViewChild('printRxOutlet', { read: ViewContainerRef }) private printVcr: ViewContainerRef;

	constructor(
		private facade: AppFacade,
		private translateService: TranslateService,
		private saveRxService: SaveRxService,
		private logger: LoggerService,
		private matIconService: MatIconService,
		private patientQuery: PatientQuery,
		private shellStore: ShellStore,
		public notesService: NotesService,
		private vcRef: ViewContainerRef,
		private cfr: ComponentFactoryResolver,
		private shellQuery: ShellQuery,
		private notesQuery: NotesQuery,
		private toothEditorQuery: ToothEditorQuery,
		private rxRulesService: RxRulesService,
		private patientAppIframeCommunicationService: PatientAppIframeCommunicationService,
		private forceSaveToothEditorService: ForceSaveToothEditorService,
		private printService: PrintService,
		private injector: Injector,
		private renderer: Renderer2,
		public aligntechNotesService: AligntechNotesService,
		private hostPlatformService: HostPlatformService,
		private rxAttachmentsApiService: RxAttachmentsApiService,
		private tracesQuery: TracesQuery,
		private readOnlyRulesService: ReadOnlyRulesService,
		private fontFaceService: FontFaceService
	) {
		super();
	}

	private readonly componentName = 'AppComponent';

	@Input() contactId: AppComponentInputs['contactId'];
	@Input() doctorId: AppComponentInputs['doctorId'];
	@Input() companyId: AppComponentInputs['companyId'];
	@Input() languageCode: AppComponentInputs['languageCode'];
	@Input() staticFilesEndpoint: AppComponentInputs['staticFilesEndpoint'];
	@Input() apiEndpoint: AppComponentInputs['apiEndpoint'];
	@Input() rxAppApiEndpoint: AppComponentInputs['rxAppApiEndpoint'];
	@Input() patientAppApiEndpoint: AppComponentInputs['patientAppApiEndpoint'];
	@Input() scannerId: AppComponentInputs['scannerId'];
	@Input() runMode: AppComponentInputs['runMode'];
	@Input() clientVersion: AppComponentInputs['clientVersion'];
	@Input() packageVersion: AppComponentInputs['packageVersion'];
	@Input() applicationMode: AppComponentInputs['applicationMode'];
	@Input() productType: AppComponentInputs['productType'];
	@Input() rxId: AppComponentInputs['rxId'];
	@Input() isRxTakenForScan: AppComponentInputs['isRxTakenForScan'];
	@Input() orderId: AppComponentInputs['orderId'];
	@Input() saveRx: AppComponentInputs['saveRx'];
	@Input() saveRxScanner: AppComponentInputs['saveRxScanner'];
	@Input() validateBeforeSend: AppComponentInputs['validateBeforeSend'];
	@Input() isReadOnly: AppComponentInputs['isReadOnly'];
	@Input() authToken: AppComponentInputs['authToken'];
	@Input() readOnlyRules: AppComponentInputs['readOnlyRules'];
	@Input() patientGuid: AppComponentInputs['patientGuid'];
	@Input() validationMode: AppComponentInputs['validationMode'];
	@Input() userRole: AppComponentInputs['userRole'];
	@Input() clonedFromRxId: AppComponentInputs['clonedFromRxId'];
	@Input() rxIdsForPrint: AppComponentInputs['rxIdsForPrint'];
	@Input() triggerPrintRx: AppComponentInputs['triggerPrintRx'];
	@Input() isHeadlessPrint: AppComponentInputs['isHeadlessPrint'];
	@Input() orientation = 'portrait';
	@Input() forceV0: AppComponentInputs['forceV0'];
	@Input() isReturn: AppComponentInputs['isReturn'];
	@Input() addNote: AppComponentInputs['addNote'];
	@Input() changeUnitType: AppComponentInputs['changeUnitType'];
	@Input() orderInformation: AppComponentInputs['orderInformation'];
	@Input() shouldAnonymizeRx: AppComponentInputs['shouldAnonymizeRx'];
	@Input() enableAllCaseTypesForAddRx: AppComponentInputs['enableAllCaseTypesForAddRx'];
	@Input() prepareRx: AppComponentInputs['prepareRx'];
	@Input() authInfo: AppComponentInputs['authInfo'];
	@Input() getUnitType: AppComponentInputs['getUnitType'];
	@Input() shouldCheckSleeveConfirmationCheckBox: AppComponentInputs['shouldCheckSleeveConfirmationCheckBox'];
	@Input() forceDisplayTeethNumberingSystem: AppComponentInputs['forceDisplayTeethNumberingSystem'];
	@Input() printStylesName: AppComponentInputs['printStylesName'];
	@Output() sleeveConfirmationUpdated: EventEmitter<any> = new EventEmitter<any>();
	@Output() rxSaved: EventEmitter<RxSavedPayload> = this.facade.rxSaved;
	@Output() rxSaveFailed: EventEmitter<any> = this.facade.rxSaveFailed;
	@Output() rxValidated: EventEmitter<any> = new EventEmitter<any>();
	@Output() rxsPrinted: EventEmitter<any> = new EventEmitter<any>();
	@Output() rxsPrintedFailed: EventEmitter<any> = new EventEmitter<any>();
	@Output() pdfRequested: EventEmitter<any> = this.facade.pdfRequested;
	@Output() noteAdded: EventEmitter<any> = new EventEmitter<any>();
	@Output() getLabNotes: EventEmitter<any> = new EventEmitter<any>();
	@Output() orderInformationUpdated: EventEmitter<RxModel> = new EventEmitter<RxModel>();
	@Output() rxUpdated: EventEmitter<RxModel> = new EventEmitter<RxModel>();
	@Output() rxPrepared: EventEmitter<RxModel> = new EventEmitter<RxModel>();
	@Output() getUnitTypeConfiguration: EventEmitter<TeethUnitType[]> = new EventEmitter<TeethUnitType[]>();

	@Input() autoDisplayPrintViewInTheDOM: boolean;

	printAlive$ = new Subject<void>();

	title = 'Rx App';
	currentApplicationVersion = rxAppVersion?.version;
	configuration = [];
	isScanner: boolean;
	isPrintPortrait = true;
	resizeHandlerTimeout: any;
	readonly RoleTypeEnum = RoleTypeEnum;

	private trySaveRx$: Subject<boolean> = new Subject<boolean>();

	isHostPlatformScanner$: Observable<boolean> = this.hostPlatformService.isScanner$;
	userRole$ = this.facade.userRole$;

	@HostListener('window:resize', [])
	handleWindowResize() {
		clearTimeout(this.resizeHandlerTimeout);
		this.resizeHandlerTimeout = setTimeout(() => {
			document.activeElement.scrollIntoView();
		}, debounceDelay);
	}

	@HostListener('window:keydown', ['$event'])
	handleKeyDown(event: KeyboardEvent) {
		if (this.isReadOnly && event.key === 'Tab') {
			event.preventDefault();
		}
	}

	ngOnChanges(changes: SimpleChanges): void {
		// Base url should be first for translation to work
		if (changes.staticFilesEndpoint) {
			const staticFilesEndpoint = changes.staticFilesEndpoint?.currentValue;

			this.facade.updateStaticFilesEndpoint({ staticFilesEndpoint });
		}
		if (changes.languageCode) {
			const languageCode = changes.languageCode?.currentValue;

			this.facade.updateLanguageCode(languageCode);
			this.translateService.reloadLang(languageCode);
			this.translateService.use(languageCode);
		}
		if (changes.apiEndpoint) {
			const apiEndpoint = changes.apiEndpoint?.currentValue;

			this.facade.updateApiEndpoint({ apiEndpoint });
		}
		if (changes.rxAppApiEndpoint) {
			const rxAppApiEndpoint = changes.rxAppApiEndpoint?.currentValue;

			this.facade.updateRxAppApiEndpoint({ rxAppApiEndpoint });
		}
		if (changes.patientAppApiEndpoint) {
			const patientAppApiEndpoint = changes.patientAppApiEndpoint?.currentValue;

			this.facade.updatePatientAppApiEndpoint({ patientAppApiEndpoint });
		}
		if (changes.contactId) {
			const contactId = +changes.contactId?.currentValue;

			this.facade.updateContactId({ contactId });
		}
		if (changes.companyId) {
			const companyId = +changes.companyId?.currentValue;

			this.facade.updateCompanyId({ companyId });
		}
		if (changes.scannerId) {
			const scannerId = changes.scannerId?.currentValue;

			this.facade.updateScannerId({ scannerId });
		}
		if (changes.clientVersion) {
			const clientVersion = changes.clientVersion?.currentValue;

			this.facade.updateClientVersion({ clientVersion });
		}
		if (changes.packageVersion) {
			const packageVersion = changes.packageVersion?.currentValue;

			this.facade.updatePackageVersion({ packageVersion });
		}
		if (changes.runMode) {
			// const runMode = changes.runMode?.currentValue;
			// this.shellStore.update({ runMode }); // TODO: implement
		}
		if (changes.applicationMode) {
			const applicationMode = changes.applicationMode?.currentValue;

			this.facade.updateApplicationMode({ applicationMode });
		}
		if (changes.productType) {
			const productType = changes.productType?.currentValue;

			this.facade.updateProductType({ productType });
		}
		if (changes.doctorId) {
			// const doctorId = changes.doctorId?.currentValue;
			// this.shellStore.update({ doctorId }); // TODO: implement
			// TODO: DOCTOR ID should also come from MIDC (Scanner only for now). When it does, filter doctor by doctor ID in shell-query
		}
		if (changes.rxId) {
			const rxId = changes.rxId?.currentValue;

			this.facade.updateRxId({ rxId });
		}
		if (changes.orderId) {
			const orderId: string = changes.orderId?.currentValue || null;

			if (orderId) {
				this.logger.info(`rx-app opened with orderId = ${orderId}`, { module: this.componentName });
			}

			this.facade.updateOrderId({ orderId });
		}
		if (changes.isReadOnly) {
			const isReadOnly = changes.isReadOnly?.currentValue;

			this.facade.updateIsReadOnly({ isReadOnly });
		}
		if (changes.readOnlyRules) {
			const readOnlyRules = changes.readOnlyRules?.currentValue;

			this.readOnlyRulesService.setRules({ readOnlyRules });
		}
		if (changes.patientGuid) {
			const patientGuid = changes.patientGuid?.currentValue || null;

			if (patientGuid && patientGuid !== emptyGuid) {
				this.facade.loadPatient(patientGuid).pipe(take(1), takeUntil(this.componentAlive$)).subscribe();
			}
		}
		if (changes.saveRx) {
			this.trySaveRx$.next(false);
		}
		if (changes.saveRxScanner) {
			this.trySaveRx$.next(true);
		}
		if (changes.validateBeforeSend) {
			this.facade
				.validateBeforeSend()
				.pipe(
					tap(isValid => {
						if (!isValid) {
							this.logger.info('Attempt to send invalid RX', {
								module: this.componentName,
								extendedParameters: {
									traces: this.tracesQuery.configurationTraceIdsAsString + this.tracesQuery.savingGettingRxTraceIds
								}
							});
						}
					}),
					takeUntil(this.componentAlive$)
				)
				.subscribe(isValid => this.rxValidated.emit(isValid));
		}
		if (changes.isRxTakenForScan) {
			const isRxTakenForScan = changes.isRxTakenForScan?.currentValue;

			this.facade.updateIsRxTakenForScan({ isRxTakenForScan });
		}
		if (changes.authToken) {
			const authToken = changes.authToken?.currentValue;

			this.facade.updateAuthToken(authToken);
		}
		if (changes.validationMode) {
			const validationMode = changes.validationMode?.currentValue;

			this.facade.updateValidationMode(validationMode);
		}
		if (changes.userRole) {
			const userRole = changes.userRole?.currentValue;

			this.facade.updateUserRole(userRole);
		}
		if (changes.clonedFromRxId) {
			const clonedFromRxId = changes.clonedFromRxId?.currentValue;

			this.facade.updateClonedFromRxId(clonedFromRxId);
		}
		if (changes.isHeadlessPrint) {
			this.facade.updateIsHeadlessPrint({ isHeadlessPrint: changes.isHeadlessPrint?.currentValue });
		}
		if (changes.triggerPrintRx) {
			const triggerRXPrint = changes.triggerPrintRx?.currentValue;

			this.printAlive$.next();
			if (triggerRXPrint) {
				// TODO: use random value instead of boolean
				this.triggerPrintRx = false;

				const rxIdsForPrint = changes.rxIdsForPrint?.currentValue || this.rxIdsForPrint;
				const printOrientation = this.orientation === 'landscape' ? PrintOrientation.landscape : PrintOrientation.portrait;

				this.facade.updatePrintOrientation({ printOrientation });
				if (rxIdsForPrint?.length > 0) {
					this.printRx({ rxIds: rxIdsForPrint });
				}
			}
		}
		if (changes.forceV0) {
			const forceV0 = changes.forceV0?.currentValue;

			this.facade.updateForceV0(forceV0);
		}
		if (changes.isReturn) {
			const isReturn = changes.isReturn?.currentValue;

			this.facade.updateIsReturn(isReturn);
		}
		if (changes.addNote) {
			const noteData = changes.addNote?.currentValue;
			const addedNote = this.notesService.addLabNote(noteData);

			this.readOnlyRulesService.update({ notes: false });

			this.noteAdded.emit(addedNote);
		}
		if (changes.orderInformation) {
			if (this.shellQuery.userRole !== RoleTypeEnum.Lab) {
				return;
			}

			const orderInformation = changes.orderInformation?.currentValue;

			this.facade.updateOrderInformation(orderInformation);
			this.orderInformationUpdated.next(this.saveRxService.prepareRxModel(this.shellQuery.rx));
		}
		if (changes.shouldAnonymizeRx) {
			this.facade.updateShouldAnonymizeRx(changes.shouldAnonymizeRx.currentValue);
		}
		if (changes.autoDisplayPrintViewInTheDOM) {
			this.shellStore.update({ autoDisplayPrintViewInTheDOM: changes.autoDisplayPrintViewInTheDOM.currentValue });
		}
		if (changes.enableAllCaseTypesForAddRx) {
			const enableAllCaseTypesForAddRx = changes.enableAllCaseTypesForAddRx.currentValue;

			this.facade.updateEnableAllCaseTypesForAddRx(enableAllCaseTypesForAddRx);
		}
		if (changes.changeUnitType) {
			const changeUnitType = changes.changeUnitType.currentValue;

			this.facade.changeUnitType(changeUnitType);
		}
		if (changes.prepareRx) {
			this.rxPrepared.emit(this.saveRxService.prepareRxModel(this.shellQuery.rx));
		}
		if (changes.shouldCheckSleeveConfirmationCheckBox) {
			const isSleeveChecked = changes.shouldCheckSleeveConfirmationCheckBox.currentValue;

			this.facade.updateIsSleeveCheckedSentByScanner(isSleeveChecked);
		}
		if (changes.forceDisplayTeethNumberingSystem) {
			const forceDisplayTeethNumberingSystem = changes.forceDisplayTeethNumberingSystem.currentValue;

			this.facade.updateForceDisplayTeethNumberingSystem(forceDisplayTeethNumberingSystem);
		}

		if (changes.getUnitType) {
			const config = this.rxRulesService.getUnitTypeConfiguration(this.shellQuery?.rx);

			this.getUnitTypeConfiguration.emit(config);
		}

		if (changes.printStylesName) {
			const printStyleName = changes.printStylesName?.currentValue;

			this.facade.updatePrintStyleName(printStyleName);
		}

		if (changes.authInfo) {
			const authInfo = changes.authInfo.currentValue;

			this.facade.updateAuthInfo(authInfo);
		}
	}

	ngOnInit(): void {
		this.facade.updateRxSessionId(uuid());
		void this.fontFaceService.load();
		this.facade.pdfRequested$.pipe(takeUntil(this.componentAlive$)).subscribe();

		this.facade.resetPreparedNewRxId();
		this.facade.loadConfigurationAndRxIfNeeded().pipe(takeUntil(this.componentAlive$)).subscribe();

		this.facade.loadAssetsForCache();
		if (this.shellQuery.userRole === RoleTypeEnum.Lab) {
			this.setLabNotesListener();
		}
		if (this.hostPlatformService.isIteroModeling || this.shellQuery.userRole === RoleTypeEnum.Lab) {
			this.setRxLoadedListener();
		}

		this.rxFormValidationListener();
		this.matIconService.registerMatIcons();
		this.notesService.setNotesArray().subscribe();
		this.aligntechNotesService.setAlignTechNotesArray().pipe(takeUntil(this.componentAlive$)).subscribe();
		this.translateSelectOptions().subscribe();
		this.logRxAppVersion();
		this.subscribeToTrackSleeveConfirmationUpdate();

		this.trySaveRx$
			.pipe(
				exhaustMap(isCalledByScanner => this.facade.tryToSaveRx(isCalledByScanner)),
				takeUntil(this.componentAlive$)
			)
			.subscribe();
	}

	ngOnDestroy(): void {
		this.patientAppIframeCommunicationService.patientAppPopupClose();
		this.unsubscribeFromPrint();
		super.ngOnDestroy();
		resetStores({ exclude: [localSettingsStoreName] });
	}

	ngAfterViewInit(): void {
		this.facade.initPendo().pipe(takeUntil(this.componentAlive$)).subscribe();
	}

	subscribeToTrackSleeveConfirmationUpdate() {
		this.facade.sleeveCheckedChangedByUser$
			.pipe(
				tap((isSleeveChecked: boolean) => this.sleeveConfirmationUpdated.next(isSleeveChecked)),
				takeUntil(this.componentAlive$)
			)
			.subscribe();
	}

	printRx({ rxIds }: { rxIds: number[] }): void {
		try {
			this.facade
				.getRxsBulk({ rxIds })
				.pipe(
					switchMap(() => this.printService.createPrintIframeElement()),
					switchMap(printRxIframe => {
						const compFactory = this.cfr.resolveComponentFactory(PrintRxComponent);
						const injector = Injector.create({
							providers: [{ provide: PRINT_IFRAME_ELEMENT, useValue: printRxIframe }],
							parent: this.injector
						});

						this.printVcr.clear();

						const compRef = this.printVcr.createComponent(compFactory, 0, injector);

						compRef.changeDetectorRef.detectChanges();

						this.renderer.appendChild(printRxIframe.contentDocument.body, compRef.location.nativeElement);

						return fromEvent(printRxIframe.contentWindow, 'afterprint');
					}),
					delay(0), // need to emit event asynchroniously because context was on print preview
					tap(() => this.rxsPrinted.emit()),
					catchError(err => {
						this.rxsPrintedFailed.emit(err);

						return of(err);
					}),
					takeUntil(merge(this.componentAlive$, this.printAlive$))
				)
				.subscribe();

			if (!this.shellQuery.apiEndpointFallback) {
				this.toggleOrientation();
			}
		} catch (err) {
			this.rxsPrintedFailed.next(err);
		}
	}

	mockGetRx(): void {
		this.facade.mockGetRx().pipe(takeUntil(this.componentAlive$)).subscribe();
	}

	toggleOrientation() {
		this.orientation = this.isPrintPortrait ? 'portrait' : 'landscape';
		this.facade.updatePrintOrientation({
			printOrientation: this.orientation === 'landscape' ? PrintOrientation.landscape : PrintOrientation.portrait
		});
	}

	rxFormValidationListener(): void {
		this.facade.validateRxForSave().subscribe();
	}

	callTryToSaveRx(): void {
		this.facade.tryToSaveRx(false).subscribe();
	}

	setLabNotesListener(): void {
		this.notesQuery.labNotes$
			.pipe(
				filter(notes => !!notes),
				tap(notes => this.getLabNotes.emit(notes)),
				takeUntil(this.componentAlive$)
			)
			.subscribe();
	}

	setRxLoadedListener(): void {
		this.shellQuery.rx$
			.pipe(
				filter(rx => !!rx),
				tap(rx => this.rxUpdated.emit(this.saveRxService.prepareRxModel(rx))),
				takeUntil(this.componentAlive$)
			)
			.subscribe();
	}

	private logRxAppVersion() {
		if (rxAppVersion?.version) {
			this.logger.info(`Rx-App loaded - version: ${rxAppVersion?.version}`, {
				module: this.componentName,
				extendedParameters: { rxAppVersion: rxAppVersion?.version }
			});
		} else {
			this.logger.error('Rx-App version number Failed to load.', {
				module: this.componentName
			});
		}
	}

	private translateSelectOptions(): Observable<TranslationChangeEvent> {
		return this.translateService.onLangChange.pipe(
			takeUntil(this.componentAlive$),
			tap(() => this.rxRulesService.translateSelectOptions())
		);
	}

	private saveRxForLab(): Observable<any> {
		return this.saveRxService.saveRx().pipe(this.facade.afterSaveRx());
	}

	private unsubscribeFromPrint() {
		this.printAlive$.next();
		this.printAlive$.complete();
	}
}
