import { Component, OnInit, Input, ViewChildren, QueryList, AfterViewInit, ElementRef, EventEmitter } from '@angular/core';
import { BaseDestroyableComponent } from '@shared/base-classes/base-destroyable';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, shareReplay, takeUntil } from 'rxjs/operators';
import { ScanOptionsFacade } from '../../scan-options.facade';
import { additionalBites } from '@shared/data/bites';
import { Bites } from '@modules/teeth-diagram/models/bites.enum';
import { BiLoggerService } from '@core/services/logger/bi-logger.service';
import { palatalFeedbackToggleClicked, restoMultiBiteClicked, restoMultiBiteToggleClicked } from '@core/services/logger/bi-actions';

@Component({
	selector: 'rx-scan-options',
	templateUrl: './scan-options.component.html',
	styleUrls: ['./scan-options.component.scss'],
	providers: [ScanOptionsFacade]
})
export class ScanOptionsComponent extends BaseDestroyableComponent implements OnInit, AfterViewInit {
	@ViewChildren(
		'NIRICapture, MultiBite, NewSleeveAttached, PreTreatmentScan, DentureCopyScan, EmergenceProfile, RestoMultiBite, PalatalScanningFeedback'
	)
	scanOptionsContainer: QueryList<ElementRef>;
	isNiriCaptureChecked$: Observable<boolean> = this.facade.isNiriCaptureChecked$;
	isNiriCaptureVisible$: Observable<boolean> = this.facade.isNiriCaptureVisible$;
	isNiriCaptureDisabled$: Observable<boolean> = this.facade.isNiriCaptureDisabled$;
	isSleeveChecked$: Observable<boolean> = this.facade.isSleeveChecked$;
	isSleeveConfirmationReadOnly$: Observable<boolean> = this.facade.isSleeveConfirmationReadOnly$;
	isSleeveConfirmationVisible$: Observable<boolean> = this.facade.isSleeveConfirmationVisible$;
	isReadOnly$: Observable<boolean> = this.facade.isReadOnly$;
	isEmergenceProfileChecked$: Observable<boolean> = this.facade.isEmergenceProfileChecked$;
	isEmergencyProfileDisabled$: Observable<boolean> = this.facade.isEmergencyProfileDisabled$;
	isEmergencyProfileVisible$: Observable<boolean> = this.facade.isEmergencyProfileVisible$;
	isRestoMultiBiteVisible$: Observable<boolean> = this.facade.isRestoMultiBiteVisible$;
	isPreTreatmentChecked$: Observable<boolean> = this.facade.isPreTreatmentChecked$;
	isPreTreatmentDisabled$: Observable<boolean> = this.facade.isPreTreatmentReadOnly$;
	isPreTreatmentVisible$: Observable<boolean> = this.facade.isPreTreatmentVisible$;
	isMultiBiteSelected$: Observable<boolean> = this.facade.isMultiBiteSelected$.pipe(shareReplay({ bufferSize: 1, refCount: true }));
	isRestoMultiBiteSelected$: Observable<boolean> = this.facade.isRestoMultiBiteSelected$.pipe(
		shareReplay({ bufferSize: 1, refCount: true })
	);
	isDentureCopyScan$: Observable<boolean> = this.facade.isDentureCopyScan$;
	additionalBites$: Observable<Record<string, boolean>> = this.facade.additionalBites$;
	isDentureCopyScanVisible$: Observable<boolean> = this.facade.isDentureCopyScanVisible$;
	isRxTakenForScan$ = this.facade.isRxTakenForScan$;
	shouldShowMultiBiteInformationMessage$: Observable<boolean> = this.facade.shouldShowMultiBiteInformationMessage$.pipe(
		shareReplay({ bufferSize: 1, refCount: true })
	);
	isPalatalFeedbackChecked$: Observable<boolean> = this.facade.isPalatalFeedbackChecked$;
	isPalatalFeedbackVisible$: Observable<boolean> = this.facade.isPalatalFeedbackVisible$;
	isPalatalFeedbackDisabled$: Observable<boolean> = this.facade.isPalatalFeedbackDisabled$;
	palatalFeedbackClicked$: EventEmitter<boolean> = new EventEmitter<boolean>();
	flexProperties: string;
	noLineStyle = false;
	additionalBites = additionalBites;

	@Input() isMultiBiteVisible: boolean;

	constructor(
		private facade: ScanOptionsFacade,
		private biLogger: BiLoggerService
	) {
		super();
	}

	ngOnInit(): void {
		this.facade.loadNiri().pipe(takeUntil(this.componentAlive$)).subscribe();
		this.facade.loadSleeveConfirmation().pipe(takeUntil(this.componentAlive$)).subscribe();
		this.facade.loadPreTreatmentAndEmergenceProfile().pipe(takeUntil(this.componentAlive$)).subscribe();
		this.facade.loadMultiBiteRestoScan().pipe(takeUntil(this.componentAlive$)).subscribe();
		this.facade.loadAdditionalBites().pipe(takeUntil(this.componentAlive$)).subscribe();
		this.facade.loadPalatalFeedback().pipe(takeUntil(this.componentAlive$)).subscribe();
		this.palatalFeedbackClicked$
			.pipe(debounceTime(300), distinctUntilChanged(), takeUntil(this.componentAlive$))
			.subscribe(val => this.biLogger.userActionEvent(palatalFeedbackToggleClicked(val)));
	}

	ngAfterViewInit(): void {
		this.flexProperties = this.getFlexProperties();
		this.scanOptionsContainer.changes.subscribe(() => {
			// changes of children inside of children changes subscription. Beware of re-rendering
			this.flexProperties = this.getFlexProperties();
		});
	}

	onNiriCaptureUpdate(isNiriCaptureChecked: boolean): void {
		this.facade.updateNiriCapture({ isNiriCaptureChecked });
	}

	onPalatalFeedbackUpdate(isPalatalFeedbackChecked: boolean): void {
		this.facade.updatePalatalFeedback({ isPalatalFeedbackChecked });
		this.palatalFeedbackClicked$.next(isPalatalFeedbackChecked);
	}

	onSleeveConfirmationUpdate(isSleeveChecked: boolean) {
		this.facade.updateSleeveConfirmation(isSleeveChecked);
	}

	onPreTreatmentUpdate(isPreTreatmentChecked: boolean) {
		this.facade.updatePreTreatmentConfirmation({ isPreTreatmentChecked });
	}

	updateScanOptionsMultiBite(checked: boolean): void {
		this.facade.updateScanOptionsMultiBite(checked);
	}

	updateScanOptionsRestoMultiBite(checked: boolean): void {
		this.facade.updateScanOptionsRestoMultiBite(checked);

		this.biLogger.userActionEvent(restoMultiBiteToggleClicked(checked));
	}

	onDentureCopyScanUpdate(isDentureCopyScan: boolean): void {
		this.facade.updateScanOptionsDentureCopyScan(isDentureCopyScan);
	}

	onEmergencyProfileUpdate(isEmergencyProfile: boolean): void {
		this.facade.updateEmergencyProfile(isEmergencyProfile);
	}

	onAdditionalBitesUpdate(isChecked: boolean, biteId: Bites) {
		this.biLogger.userActionEvent(restoMultiBiteClicked(isChecked, biteId));

		this.facade.updateAdditionalBites({ [biteId]: isChecked });
	}

	get isProcedureFlow(): boolean {
		return this.facade.isProcedureFlow;
	}

	isBiteSelected(biteId): Observable<boolean> {
		return this.additionalBites$.pipe(map(bite => bite[biteId] || false));
	}

	private getFlexProperties(): string {
		if (this.scanOptionsContainer?.length === 5) {
			this.noLineStyle = true;

			return '0 1 calc(50% - 15px)';
		}

		return this.scanOptionsContainer?.length % 2 === 0 ? '0 1 calc(50% - 15px)' : '0 1 calc(33% - 30px)';
	}
}
