import 'zone.js';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { akitaConfig, persistState } from '@datorama/akita';

import { initFn } from './bootstrap/init';
import { DirectBootstrapModule } from './bootstrap/modules/direct-bootstrap.module';
import { WEBCOMPONENT_ROOT_ELEMENT_GETTER } from '@shared/tokens';

const { providers } = initFn(akitaConfig, persistState);

platformBrowserDynamic([
	...providers,
	{
		provide: WEBCOMPONENT_ROOT_ELEMENT_GETTER,
		useValue: () => document.getElementsByTagName('rx-app')?.[0]
	}
])
	.bootstrapModule(DirectBootstrapModule)
	// eslint-disable-next-line no-console
	.catch(err => console.error(err));
