import { APP_INITIALIZER, ErrorHandler, Injector, Provider, Type } from '@angular/core';
import { createCustomElement } from '@angular/elements';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { MAT_RIPPLE_GLOBAL_OPTIONS, RippleGlobalOptions } from '@angular/material/core';
import { DateAdapter } from '@angular/material/core';
import { FontsPreloadService } from '@core/services/app-settings/fonts-preload.service';
import { GlobalErrorHandlerService } from '@core/services/global-error-handler.service';
import { HttpErrorInterceptor } from '@core/interceptors/http-error.interceptor';
import { AuthInterceptor } from '@core/interceptors/auth.interceptor';
import { AppSettingsService } from '@core/services/app-settings/app-settings.service';
import { LoaderInterceptor } from '@core/interceptors/loader.interceptor';
import { UserSettingsDateAdapter } from '@core/services/user-settings-date-adapter';
import { ShellQuery } from '@shared/store/shell/shell-query';

const globalRippleConfig: RippleGlobalOptions = {
	disabled: true,
	animation: {
		enterDuration: 0,
		exitDuration: 0
	}
};

export const provideRootProviders = ({ componentTag, component }: { componentTag: string; component: Type<any> }): Provider[] => [
	{
		provide: HTTP_INTERCEPTORS,
		useClass: LoaderInterceptor,
		multi: true
	},
	{
		provide: DateAdapter,
		useClass: UserSettingsDateAdapter,
		deps: [ShellQuery]
	},
	{
		provide: APP_INITIALIZER,
		multi: true,
		deps: [FontsPreloadService],
		useFactory: (fontsPreloadService: FontsPreloadService) => {
			return () => fontsPreloadService.loadPreloadSettings();
		}
	},
	{
		provide: APP_INITIALIZER,
		multi: true,
		deps: [AppSettingsService, Injector],
		useFactory: (appSettingsService: AppSettingsService, inject: Injector) => {
			return () => {
				return appSettingsService.loadAppSettings().then(() => {
					if (!!customElements.get(componentTag)) {
						return;
					}

					const appComponent = createCustomElement(component, {
						injector: inject
					});

					customElements.define(componentTag, appComponent);
				});
			};
		}
	},
	{ provide: MAT_RIPPLE_GLOBAL_OPTIONS, multi: true, useValue: globalRippleConfig },
	{ provide: ErrorHandler, useClass: GlobalErrorHandlerService },
	{
		provide: HTTP_INTERCEPTORS,
		useClass: AuthInterceptor,
		multi: true
	},
	{
		provide: HTTP_INTERCEPTORS,
		useClass: HttpErrorInterceptor,
		multi: true
	}
];
