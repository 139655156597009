import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { WEBCOMPONENT_ROOT_ELEMENT_GETTER, WebComponentRootElementGetter } from '@shared/tokens';
import { type AppComponentInputs } from 'src/app/app.component';

@Injectable({
	providedIn: 'root'
})
export class FontFaceService {
	private static fontsLoaded = false;

	constructor(
		@Inject(DOCUMENT) private documentRef: Document,
		@Inject(WEBCOMPONENT_ROOT_ELEMENT_GETTER) private webComponentRootElementGetter: WebComponentRootElementGetter
	) {}

	load(): Promise<unknown> {
		if (FontFaceService.fontsLoaded) {
			return Promise.resolve();
		}

		return Promise.all(
			this.getFontFaces().map(fontFace => {
				return fontFace.load().then(
					loadedFontFace => this.documentRef.fonts.add(loadedFontFace),
					() => {}
				);
			})
		).then(() => (FontFaceService.fontsLoaded = true));
	}

	private get staticFilesEndpoint(): string {
		return (this.webComponentRootElementGetter() as HTMLElement & AppComponentInputs)?.staticFilesEndpoint || '';
	}

	private getFontFaces(): FontFace[] {
		const staticFilesEndpoint = this.staticFilesEndpoint;
		const url = (fontName: string) => `url(${staticFilesEndpoint}/assets/fonts/${fontName})`;

		return [
			new FontFace('NeueHaasGroteskDisplay', url('NeueHaasDisplay-Light.woff'), { weight: '400' }),
			new FontFace('NeueHaasGroteskDisplay', url('NeueHaasDisplay-Roman.woff'), { weight: '450' }),
			new FontFace('NeueHaasGroteskDisplay', url('NeueHaasDisplay-Medium.woff'), { weight: '500' }),
			new FontFace('Material Icons', url('material-icons.woff2'), { weight: '400', style: 'normal' })
		];
	}
}
