import {
	BiAction,
	BiLocationObject,
	BiObjectName,
	BiObjectType,
} from '@core/services/logger/interfaces/bi.enums';
import { Bites } from '@modules/teeth-diagram/models/bites.enum';
import { IUserActionEvent } from '@core/services/logger/interfaces/bi.events';

export const restoMultiBiteClicked = (value: boolean, name: Bites): IUserActionEvent => ({
	locationParentObject: BiLocationObject.RxContainer,
	objectName: name,
	action: BiAction.Clicked,
	objectType: BiObjectType.CheckBox,
	selectedValue: String(!!value),
	automationId: ''
});

export const restoMultiBiteToggleClicked = (value: boolean): IUserActionEvent => ({
	locationParentObject: BiLocationObject.RxContainer,
	objectName: BiObjectName.MultiBiteRestoScan,
	action: BiAction.Clicked,
	objectType: BiObjectType.ToggleButton,
	selectedValue: String(!!value),
	automationId: ''
});

export const sendUpdateAttachmentLater = (value: boolean): IUserActionEvent => ({
	locationParentObject: BiLocationObject.RxContainer,
	objectName: BiObjectName.IsDraft,
	action: BiAction.Clicked,
	objectType: BiObjectType.ToggleButton,
	selectedValue: String(!!value),
	automationId: ''
});

export const registerForDesignSuit = (selectedValue: string, bannerType: string): IUserActionEvent => ({
	locationParentObject: BiLocationObject.RxContainer,
	objectName: bannerType,
	action: BiAction.Clicked,
	objectType: BiObjectType.Button,
	selectedValue,
	automationId: ''
});

export const palatalFeedbackToggleClicked = (value: boolean): IUserActionEvent => ({
	locationParentObject: BiLocationObject.RxContainer,
	objectName: BiObjectName.PalatalScanningFeedback,
	action: BiAction.Clicked,
	objectType: BiObjectType.ToggleButton,
	selectedValue: String(value),
	automationId: ''
});
